<template>
  <div>
    <!-- access root props via $root -->
    <br>
    Home
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
  },
  methods: {},
}
</script>